.footer {
    width: 100%;
    height: 140px;
    background: #ECABC7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    padding-right: 0;
    margin-top: 50px;
    font-optical-sizing: auto;
    /*margin-top: 20px;*/
  }
  
  .socialMedia svg {
    color: white;
    margin: 20px;
    margin-top: 10px;
    font-size: 70px;
    cursor: pointer;
  }
  
  .footer p {
    color: black;
    font-size: 15px;
  }
  
  @media only screen and (max-width: 600px) {
    .footer svg {
      font-size: 50px;
    }

    .footer {
      height: 150px;
      margin-top: 15px;
    }
    .footer p {
      font-size: 12px;
    }
  }