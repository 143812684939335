.custom-navbar {
  background-color: #ECABC7;
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo{
  width: 100px;
  height: 100px;
}

.navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  font-size: 25px;
  font-family: 'Playfair Dispaly', serif;
}

.nav-link {
  color: white !important;
}

.center{
  display: flex;
  flex-direction: row;
}

