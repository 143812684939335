.home {
  width: 100%;  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

p {
  text-align: center;
}

h2 {
  font-family: "Great Vibes", cursive;
  font-size: 80px !important;
  align-items: center;
}

.title {
  color:#fda6ea;
  padding-top: 35px;
  padding-left: 20px;
  padding-right: 20px;
}

.rosie {
  color:black;
  padding-top: 15px;
}

.description {
  font-size: 30px; 
  align-items: center;
  margin-top: 30px;
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
}


.imageStyle {
  width: 250px;
  margin-bottom: 15px; 
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}



@media only screen and (max-width: 480px) {
  .description {
    font-size: 40px;
  }
  .title{
    font-size: 70px;
  }

  .rosie {
    font-size: 70px;
  }

  .imageStyle {
    width: 205px;
  }
  
  p {
    font-size: 15px;
  }
}

