.App {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    background-color: white;
    text-align: center;
    padding: 0;
  }

  
  
  body {
    padding: 0;
    margin: 0;
  }